import { render, staticRenderFns } from "./default.vue?vue&type=template&id=d444a2bc&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=d444a2bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d444a2bc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBanner: require('/app/components/ui/banner/top-banner.vue').default,NavbarMenu: require('/app/components/navbar/navbar-menu.vue').default,Spinner: require('/app/components/ui/spinner/spinner.vue').default,NavbarProfileUser: require('/app/components/navbar/navbar-profile-user.vue').default,NavbarTopbarColEnrolled: require('/app/components/navbar/navbar-topbar-col-enrolled.vue').default,NavbarTopbarColNotEnrolled: require('/app/components/navbar/navbar-topbar-col-not-enrolled.vue').default,BaseLayout: require('/app/components/ui/layout/base-layout.vue').default})
